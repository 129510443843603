import React, { useState } from "react";

const defaultSchema = [{"type":"text","name":"name","label":"Nombre","placeholder":"Ingrese el nombre","required":false}]

const DynamicForm = ({ schema }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [areaSchema, setAreaSchema] = useState(JSON.stringify(schema ?? defaultSchema));
  const [fieldsToRender, setFieldsToRender] = useState(schema ?? defaultSchema);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    schema.forEach((field) => {
      if (field.required && !formData[field.name]) {
        formIsValid = false;
        errors[field.name] = "This field is required";
      }
    });

    setFormErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      console.log(formData);
    }
  };

  const renderField = (field) => {
    switch (field.type) {
      case "select":
        return (
          <select
            className="form-control"
            name={field.name}
            onChange={handleChange}
            required={field.required}
          >
            <option value="">Seleccionar</option>
            {field.options?.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "radio":
        return field.options.map((option, index) => (
          <input
            className="form-control"
            key={index}
            type="radio"
            name={field.name}
            value={option.value}
            label={option.label}
            onChange={handleChange}
            required={field.required}
            checked={formData[field.name] === option.value}
          />
        ));
      case "checkbox":
        return (
          <input
            className="form-control"
            type="checkbox"
            name={field.name}
            label={field.label}
            onChange={handleChange}
            required={field.required}
            checked={formData[field.name]}
          />
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            rows={3}
            name={field.name}
            placeholder={field.placeholder}
            onChange={handleChange}
            required={field.required}
            value={formData[field.name] || ""}
          />
        );
      default:
        // Handle other HTML5 input types like date, email, etc.
        return (
          <input
            className="form-control"
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            onChange={handleChange}
            required={field.required}
            value={formData[field.name] || ""}
          />
        );
    }
  };

  return (
    <div className="row">
      <div className="col-6">
        <label className="form-label">Campos personalizados (schema)</label>
        <textarea
          rows={20}
          className="form-control"
          onChange={({ target }) => setAreaSchema(target.value)}
          value={areaSchema}
          name="event_template[custom_fields]"
        />
      </div>

      <form className="col-6 p-2" onSubmit={handleSubmit}>
        <label className="form-label mb-0">Preview de campos personalizados:</label>
        <hr className="my-2" />
        {fieldsToRender.map((field, fieldIndex) => (
          <div key={fieldIndex} className="mt-3">
            <label className="form-label">{field.label}</label>
            {renderField(field)}
            {formErrors[field.name] && (
              <div className="text-danger">{formErrors[field.name]}</div>
            )}
          </div>
        ))}
        <button className="btn btn-return mt-3" type="button" onClick={() => setFieldsToRender(JSON.parse(areaSchema))}>
          Generar
        </button>
      </form>
    </div>
  );
};

export default DynamicForm;
