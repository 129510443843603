import React from 'react';
import DateTimeSchedule from '../../form/components/DateTimeSchedule';

const EventsSchedule = ({railsModel, railsField, railsLabel, initSchedule, changed}) => {
    

    return (
        <div>
            <label className="form-label">{ railsLabel }</label>

            <div className={changed ? '--changed' : ''}>
                <DateTimeSchedule
                    railsModel={ railsModel } 
                    railsField={ railsField }
                    railsLabel={ railsLabel }
                    initSchedule={ initSchedule }
                />
            </div>
        </div>
    );
}

export default EventsSchedule;
